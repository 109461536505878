import landingPageModule from "../landingPageModule"
import { EnvironmentUrls, FormEvents, DeviceMeta, EventStream } from "Roblox";

function avatarLandingPageController($scope, avatarLandingConstants, landingPageService, eventStreamService, $uibModal) {
    "ngInject";
    
    $scope.landingLayout.displayAvatarSignup = true;

    $scope.avatarSignupLayout = {
        currentLayout: avatarLandingConstants.pages.bodyPage,
        layouts: {
            bodyPage: avatarLandingConstants.pages.bodyPage,
            accessoryPage: avatarLandingConstants.pages.accessoryPage,
            signupPage: avatarLandingConstants.pages.signupPage
        },
        currentTab: avatarLandingConstants.tabs.clothing,
        tabs: {
            head: avatarLandingConstants.tabs.head,
            clothing: avatarLandingConstants.tabs.clothing,
            color: avatarLandingConstants.tabs.color
        }
    };

    var clickCount = {
        body_clicks: 0,
        [`tab_${avatarLandingConstants.tabs.head}_clicks`]: 0,
        [`tab_${avatarLandingConstants.tabs.clothing}_clicks`]: 0,
        [`tab_${avatarLandingConstants.tabs.color}_clicks`]: 0,
        randomize_clicks: 0,
        head_clicks: 0,
        clothing_clicks: 0,
        color_clicks: 0
    }

    $scope.ftuxAvatarAssetMap = {};
    $scope.handleYoutubeClick = function() {
        var modalInstance = $uibModal.open({
            animation: false,
            size:'youtube-large', // this appends modal-youtube-large class to modal.dialog
            templateUrl: avatarLandingConstants.templates.youtubeModal, // this points to youtubeModal.html
        });
        modalInstance.opened.then( function(){
            var eventParams = {
                btn: avatarLandingConstants.events.videoPreview
            }
            eventStreamService.sendEventWithTarget(avatarLandingConstants.events.buttonClick, avatarLandingConstants.events.context, eventParams);
        });
        modalInstance.result.then( function(){
            // no handler needed here.
        }, function(){
            var eventParams = {
                aType: avatarLandingConstants.events.dismiss
            }
            eventStreamService.sendEventWithTarget(avatarLandingConstants.events.modalAction, avatarLandingConstants.events.context, eventParams);
        });
    }
    // handle back button click
    $scope.backClick = function (fromPage) {
        if (fromPage === avatarLandingConstants.pages.accessoryPage) {
            $scope.avatarSignupLayout.currentLayout = avatarLandingConstants.pages.bodyPage;
        } else if (fromPage === avatarLandingConstants.pages.signupPage) {
            $scope.avatarSignupLayout.currentLayout = avatarLandingConstants.pages.accessoryPage;
        }
    }

    // handle continue button click
    $scope.continueClick = function () {
        $scope.avatarSignupLayout.currentLayout = avatarLandingConstants.pages.signupPage;
        buildSignupParams();

        var eventParams = {
            btn: avatarLandingConstants.events.continue,
        }

        Object.assign(eventParams, clickCount);

        eventStreamService.sendEventWithTarget(avatarLandingConstants.events.buttonClick, avatarLandingConstants.events.context, eventParams);
    }

    // set up all preselected avatars
    $scope.avatarIds = $scope.avatarSignupMetadata.displayAvatarV2 ? avatarLandingConstants.avatarIds.preSelectedIds.v2 : avatarLandingConstants.avatarIds.preSelectedIds.v1;

    $scope.avatarIds = shufflePreSelectedIds();
    // handle body select on body page
    $scope.handleBodySelect = function (avatarId, index) {
        $scope.avatarSignupLayout.currentLayout = avatarLandingConstants.pages.accessoryPage;
        $scope.avatarSignupLayout.currentTab = avatarLandingConstants.tabs.clothing;
        var partsArr = avatarId.split('_');
        $scope.avatarSignupMetadata.bodyId = partsArr[0];
        $scope.avatarSignupMetadata.headId = partsArr[1];
        $scope.avatarSignupMetadata.clothingId = partsArr[2];
        $scope.avatarSignupMetadata.colorId = partsArr[3];
        buildAvatarUrl();
        parseBodyParts();
        clickCount.body_clicks++;
        if (clickCount.body_clicks === 1) {
            eventStreamService.sendEventWithTarget(avatarLandingConstants.events.buttonClick, avatarLandingConstants.events.context,
                {
                    btn: avatarLandingConstants.events.avatarSelection,
                    body: $scope.avatarSignupMetadata.bodyId,
                    clothing: $scope.avatarSignupMetadata.clothingId,
                    head: $scope.avatarSignupMetadata.headId,
                    color: $scope.avatarSignupMetadata.colorId,
                    index: index
                });
        }
    }

    // handle tab click on accessory page
    $scope.handleTabClick = function (tabName) {
        if ($scope.avatarSignupLayout.currentTab != tabName) {
            $scope.avatarSignupLayout.currentTab = tabName;
            clickCount[`tab_${tabName}_clicks`]++;
        }
    }

    // handle each accessory select on accessory page
    $scope.handlePartSelect = function (position, parts) {
        $scope.landingLayout.thumbnailLoaded = false;
        angular.forEach(parts, function (part, index) {
            if (position != index) {
                part.checked = false;
            } else {
                part.checked = true;
                updateThumbnail(part.name);
            }
        });

        if ($scope.avatarSignupLayout.currentTab === avatarLandingConstants.tabs.head) {
            clickCount.head_clicks++;
        }
        else if ($scope.avatarSignupLayout.currentTab === avatarLandingConstants.tabs.clothing) {
            clickCount.clothing_clicks++;
        }
        else if ($scope.avatarSignupLayout.currentTab === avatarLandingConstants.tabs.color) {
            clickCount.color_clicks++;
        }
    }

    // handle randomize button click
    $scope.handleRandomize = function () {
        var bodyIds = flattenArr($scope.ftuxAvatarAssetMap.bodies);
        var clothingIds = flattenArr($scope.ftuxAvatarAssetMap.clothing);
        var colorIds = flattenArr($scope.ftuxAvatarAssetMap.bodyColors);
        var bodyId = randomPickFromArray(bodyIds);

        var genderArray = ['m', 'f'];
        var headId = $scope.avatarSignupMetadata.displayAvatarV2 ? bodyId : bodyId.charAt(0) + randomPickFromArray(genderArray);

        var clothingId = randomPickFromArray(clothingIds);
        var colorId = randomPickFromArray(colorIds);
        $scope.avatarSignupMetadata.headId = headId;
        $scope.avatarSignupMetadata.bodyId = bodyId;
        $scope.avatarSignupMetadata.clothingId = clothingId;
        $scope.avatarSignupMetadata.colorId = colorId;
        buildAvatarUrl();
        parseBodyParts();
        clickCount.randomize_clicks++;
    }

    function shufflePreSelectedIds() {
        // to shuffle the selected avatar
        var array = [0,2,4,6];
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
            [array[i], array[j]] = [array[j], array[i]];
        }

        var avatarIds = $scope.avatarIds;
        var shuffledArray = [];

        for ( let i of array) {
            shuffledArray.push(avatarIds[i]);
            shuffledArray.push(avatarIds[i+1]);
        }
        return shuffledArray;
    }

    // translate selected body parts to sign up parameters
    function buildSignupParams() {
        var color = $scope.avatarSignupMetadata.colorId;
        var head = $scope.avatarSignupMetadata.headId;
        var clothing = $scope.avatarSignupMetadata.clothingId;
        var body = $scope.avatarSignupMetadata.bodyId;

        var assetIds = [];
        var bodyObj = findElementinArr($scope.ftuxAvatarAssetMap.bodies, body) || {};
        var headIds = findElementinArr($scope.ftuxAvatarAssetMap.heads, head) || [];
        var clothingIds = findElementinArr($scope.ftuxAvatarAssetMap.clothing, clothing) || [];
        assetIds = assetIds.concat(bodyObj.assetIds, headIds, clothingIds);
        var colorId = findElementinArr($scope.ftuxAvatarAssetMap.bodyColors, color) || [];

        var avatarSignupParams = {
            assetIds: assetIds,
            bodyColorId: colorId,
            bodyTypeScale: bodyObj.scale.bodyType,
            headScale: bodyObj.scale.head,
            heightScale: bodyObj.scale.height,
            widthScale: bodyObj.scale.width,
            proportionScale: bodyObj.scale.proportion
        };

        Object.assign($scope.avatarSignupMetadata, avatarSignupParams);
    }

    // util funciton to build avatar url from body parts
    function buildAvatarUrl() {
        var version = $scope.avatarSignupMetadata.displayAvatarV2 ? 'v5': 'v3';
        $scope.avatarSignupMetadata.avatarUrl = `${avatarLandingConstants.urls.avatarImage}${ $scope.avatarSignupMetadata.bodyId }_${ $scope.avatarSignupMetadata.headId }_${ $scope.avatarSignupMetadata.clothingId }_${ $scope.avatarSignupMetadata.colorId }_${version}.png`;
    }

    // util function to find element from array
    function findElementinArr(arr, key) {
        for (var i in arr) {
            if (Object.keys(arr[i]).includes(key)) {
                return arr[i][key];
            }
        }
        return false;
    }

    // util function to pick random element from array
    function randomPickFromArray(arr) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

    // util to flatten array
    function flattenArr(arr) {
        var rst = [];
        arr.forEach( (item) => {
            rst = rst.concat(Object.keys(item))
        })
        return rst;
    }

    //get the right parts for clothing, head and skin tone, and then update the checkers' states.
    function parseBodyParts() {
        if ($scope.avatarSignupMetadata.displayAvatarV2) {
            $scope.clothings = buildPartChecks(avatarLandingConstants.outfitMapping.v2[$scope.avatarSignupMetadata.bodyId]);
            $scope.heads = buildPartChecks(avatarLandingConstants.headMapping.v2[$scope.avatarSignupMetadata.headId]);
            $scope.colors = buildPartChecks(avatarLandingConstants.colorMapping.v2);
        } else {
            $scope.clothings = buildPartChecks(avatarLandingConstants.outfitMapping.v1[$scope.avatarSignupMetadata.bodyId]);
            $scope.heads = buildPartChecks(avatarLandingConstants.headMapping.v1[$scope.avatarSignupMetadata.headId]);
            $scope.colors = buildPartChecks(avatarLandingConstants.colorMapping.v1);
        }
    }

    //helper function to build checkers' states.
    function buildPartChecks(bodyPartIds) {
        var partChecks = [];
        var headId = $scope.avatarSignupMetadata.headId;
        var clothingId = $scope.avatarSignupMetadata.clothingId;
        var colorId = $scope.avatarSignupMetadata.colorId;

        angular.forEach(bodyPartIds, function (id, key) {
            var checked = false;
            if (id.endsWith('head')) {
                checked = id.startsWith(headId);
            }
            if (id.endsWith('clothing')) {
                checked = id.split('_')[2] === clothingId;
            }
            if (id.endsWith('color')) {
                checked = id.startsWith(colorId);
            }
            partChecks.push({
                name: id,
                checked: checked
            });
        });
        return partChecks;
    }

    // update thumbnail after body part gets selected
    function updateThumbnail(partName) {
        if (partName.endsWith(avatarLandingConstants.tabs.head)) {
            $scope.avatarSignupMetadata.headId = partName.replace('_head', '');
        }
        if (partName.endsWith(avatarLandingConstants.tabs.clothing)) {
            $scope.avatarSignupMetadata.clothingId = partName.split("_")[2];
        }
        if (partName.endsWith(avatarLandingConstants.tabs.color)) {
            $scope.avatarSignupMetadata.colorId = partName.split('_')[0];
        }
        buildAvatarUrl();
    }

    function init() {
        landingPageService.getMetadata()
            .then(function (data) {
                if (!data) {
                    return;
                }
                var version = $scope.avatarSignupMetadata.displayAvatarV2 ? "v2" : "v1";
                $scope.ftuxAvatarAssetMap = JSON.parse(data[avatarLandingConstants.ftuxAvatarAssetMap])[version];
                console.log("assetMap: ", $scope.ftuxAvatarAssetMap);
            });
    };

    init();
};

landingPageModule.controller("avatarLandingPageController", avatarLandingPageController);

export default avatarLandingPageController;

import landingPageModule from '../landingPageModule';
import { EnvironmentUrls } from "Roblox";

function signupService(httpService, signupConstants) {
    "ngInject";

    function signup(params, signUpUrl) {
        var url = signUpUrl || EnvironmentUrls.authApi + signupConstants.urls.signUpApi;
        var urlConfig = {
            url: url
        };

        return httpService.httpPost(urlConfig, params, true);
    };

    // This call is used for experimentation / AB testing variants
    function getMetadataV2() {
        var url = EnvironmentUrls.authApi + signupConstants.urls.metadataV2
        var urlConfig = {
            url: url
        };

        return httpService.httpGet(urlConfig);
    }

    var signupService = {
        signup: signup,
        getMetadataV2: getMetadataV2
    };

    return signupService;
}

landingPageModule.factory("signupService", signupService);

export default signupService;
import landingPageModule from '../landingPageModule';

const avatarLandingConstants = {
    templates: {
        avatarLandingPage: "avatar-landing",
        youtubeModal: "youtube-modal"
    },
    pages: {
        bodyPage: "bodyPage",
        accessoryPage: "accessoryPage",
        signupPage: "signupPage"
    },
    ftuxAvatarAssetMap: "ftuxAvatarAssetMap",
    tabs: {
        head: 'head',
        clothing: 'clothing',
        color: 'color'
    },
    urls: {
        avatarImage: 'https://images.rbxcdn.com/'
    },

    avatarIds: {
        // Here are all the identifiers for the preselected avatar bodies.
        // The pattern is body_head_clothing_color
        preSelectedIds: {
            v1: [
                'rm_rm_rsg_ng_body',
                'rf_rf_rsb_lo_body',
                'sm_sm_gt_po_body',
                'sf_sf_dj_lo_body',
                'nm_nm_ps_bn_body',
                'nf_nf_pt_ng_body',
                'c_cm_gj_do_body',
                'c_cf_dw_bl_body'
            ],
            v2: [
                'classic-m_classic-m_roblox-shirt_pastelOrange_body',
                'classic-f_classic-f_denim-white_nougat_body',
                'neo-m_neo-m_zipup-jacket_darkOrange_body',
                'neo-f_neo-f_purple-top_lightOrange_body',
                'style-m_style-m_guitar-tee_pastelOrange_body',
                'style-f_style-f_denim-jacket_lightOrange_body',
                'rthro-m_rthro-m_rbx-green_nougat_body',
                'rthro-f_rthro-f_rbx-black_lightOrange_body'
            ]
        }
    },
    // this mapping indicates head options based on the body selection of user.
    headMapping: {
        v1: {
            cm: ['cm_head', 'cf_head'],
            cf: ['cm_head', 'cf_head'],
            rm: ['rm_head', 'rf_head'],
            rf: ['rm_head', 'rf_head'],
            nm: ['nm_head', 'nf_head'],
            nf: ['nm_head', 'nf_head'],
            sm: ['sm_head', 'sf_head'],
            sf: ['sm_head', 'sf_head']
        },
        v2: {
           'classic-m': ['classic-m_head', 'classic-f_head'],
           'classic-f': ['classic-m_head', 'classic-f_head'],
           'rthro-m': ['rthro-m_head', 'rthro-f_head'],
           'rthro-f': ['rthro-m_head', 'rthro-f_head'],
           'style-m': ['style-m_head', 'style-f_head'],
           'style-f': ['style-m_head', 'style-f_head'],
           'neo-m': ['neo-m_head', 'neo-f_head'],
           'neo-f': ['neo-m_head', 'neo-f_head']
        }
    },
    // this mapping indicated clothing options based on the body selection of user.
    outfitMapping: {
        v1: {
            c: [
                'c_cf_dj_ng_clothing',
                'c_cf_dw_ng_clothing',
                'c_cf_gj_ng_clothing',
                'c_cf_gt_ng_clothing',
                'c_cf_ps_ng_clothing',
                'c_cf_pt_ng_clothing',
                'c_cf_rsb_ng_clothing',
                'c_cf_rsg_ng_clothing'
            ],
            nf: [
                'nf_nf_dj_ng_clothing',
                'nf_nf_dw_ng_clothing',
                'nf_nf_gj_ng_clothing',
                'nf_nf_gt_ng_clothing',
                'nf_nf_ps_ng_clothing',
                'nf_nf_pt_ng_clothing',
                'nf_nf_rsb_ng_clothing',
                'nf_nf_rsg_ng_clothing'
            ],
            nm: [
                'nm_nm_dj_ng_clothing',
                'nm_nm_dw_ng_clothing',
                'nm_nm_gj_ng_clothing',
                'nm_nm_gt_ng_clothing',
                'nm_nm_ps_ng_clothing',
                'nm_nm_pt_ng_clothing',
                'nm_nm_rsb_ng_clothing',
                'nm_nm_rsg_ng_clothing'
            ],
            rf: [
                'rf_rf_dj_ng_clothing',
                'rf_rf_dw_ng_clothing',
                'rf_rf_gj_ng_clothing',
                'rf_rf_gt_ng_clothing',
                'rf_rf_ps_ng_clothing',
                'rf_rf_pt_ng_clothing',
                'rf_rf_rsg_ng_clothing'
            ],
            rm: [
                'rm_rf_dj_ng_clothing',
                'rm_rf_dw_ng_clothing',
                'rm_rf_gj_ng_clothing',
                'rm_rf_gt_ng_clothing',
                'rm_rf_ps_ng_clothing',
                'rm_rf_pt_ng_clothing',
                'rm_rf_rsg_ng_clothing'
            ],
            sf: [
                'sf_sf_dj_ng_clothing',
                'sf_sf_dw_ng_clothing',
                'sf_sf_gj_ng_clothing',
                'sf_sf_gt_ng_clothing',
                'sf_sf_ps_ng_clothing',
                'sf_sf_pt_ng_clothing',
                'sf_sf_rsb_ng_clothing',
                'sf_sf_rsg_ng_clothing'
            ],
            sm: [
                'sm_sm_dj_ng_clothing',
                'sm_sm_dw_ng_clothing',
                'sm_sm_gj_ng_clothing',
                'sm_sm_gt_ng_clothing',
                'sm_sm_ps_ng_clothing',
                'sm_sm_pt_ng_clothing',
                'sm_sm_rsb_ng_clothing',
                'sm_sm_rsg_ng_clothing'
            ]
        },
        v2: {
            'classic-f': [
                'classic-f_classic-f_denim-jacket_nougat_clothing',
                'classic-f_classic-f_denim-white_nougat_clothing',
                'classic-f_classic-f_guitar-tee_nougat_clothing',
                'classic-f_classic-f_purple-top_nougat_clothing',
                'classic-f_classic-f_rbx-black_nougat_clothing',
                'classic-f_classic-f_rbx-green_nougat_clothing',
                'classic-f_classic-f_roblox-shirt_nougat_clothing',
                'classic-f_classic-f_zipup-jacket_nougat_clothing'
            ],
            'classic-m': [
                'classic-m_classic-m_denim-jacket_nougat_clothing',
                'classic-m_classic-m_denim-white_nougat_clothing',
                'classic-m_classic-m_guitar-tee_nougat_clothing',
                'classic-m_classic-m_purple-top_nougat_clothing',
                'classic-m_classic-m_rbx-black_nougat_clothing',
                'classic-m_classic-m_rbx-green_nougat_clothing',
                'classic-m_classic-m_roblox-shirt_nougat_clothing',
                'classic-m_classic-m_zipup-jacket_nougat_clothing'
            ],
            'neo-f': [
                'neo-f_neo-f_denim-jacket_nougat_clothing',
                'neo-f_neo-f_denim-white_nougat_clothing',
                'neo-f_neo-f_guitar-tee_nougat_clothing',
                'neo-f_neo-f_purple-top_nougat_clothing',
                'neo-f_neo-f_rbx-black_nougat_clothing',
                'neo-f_neo-f_rbx-green_nougat_clothing',
                'neo-f_neo-f_roblox-shirt_nougat_clothing',
                'neo-f_neo-f_zipup-jacket_nougat_clothing'
            ],
            'neo-m': [
                'neo-m_neo-m_denim-jacket_nougat_clothing',
                'neo-m_neo-m_denim-white_nougat_clothing',
                'neo-m_neo-m_guitar-tee_nougat_clothing',
                'neo-m_neo-m_purple-top_nougat_clothing',
                'neo-m_neo-m_rbx-black_nougat_clothing',
                'neo-m_neo-m_rbx-green_nougat_clothing',
                'neo-m_neo-m_roblox-shirt_nougat_clothing',
                'neo-m_neo-m_zipup-jacket_nougat_clothing'
            ],
            'rthro-f': [
                'rthro-f_rthro-f_denim-jacket_nougat_clothing',
                'rthro-f_rthro-f_denim-white_nougat_clothing',
                'rthro-f_rthro-f_guitar-tee_nougat_clothing',
                'rthro-f_rthro-f_purple-top_nougat_clothing',
                'rthro-f_rthro-f_rbx-black_nougat_clothing',
                'rthro-f_rthro-f_roblox-shirt_nougat_clothing',
                'rthro-f_rthro-f_zipup-jacket_nougat_clothing'
            ],
            'rthro-m': [
                'rthro-m_rthro-m_denim-jacket_nougat_clothing',
                'rthro-m_rthro-m_denim-white_nougat_clothing',
                'rthro-m_rthro-m_guitar-tee_nougat_clothing',
                'rthro-m_rthro-m_purple-top_nougat_clothing',
                'rthro-m_rthro-m_rbx-black_nougat_clothing',
                'rthro-m_rthro-m_roblox-shirt_nougat_clothing',
                'rthro-m_rthro-m_zipup-jacket_nougat_clothing'
            ],
            'style-f': [
                'style-f_style-f_denim-jacket_nougat_clothing',
                'style-f_style-f_denim-white_nougat_clothing',
                'style-f_style-f_guitar-tee_nougat_clothing',
                'style-f_style-f_purple-top_nougat_clothing',
                'style-f_style-f_rbx-black_nougat_clothing',
                'style-f_style-f_roblox-shirt_nougat_clothing',
                'style-f_style-f_zipup-jacket_nougat_clothing'
            ],
            'style-m': [
                'style-m_style-m_denim-jacket_nougat_clothing',
                'style-m_style-m_denim-white_nougat_clothing',
                'style-m_style-m_guitar-tee_nougat_clothing',
                'style-m_style-m_purple-top_nougat_clothing',
                'style-m_style-m_rbx-black_nougat_clothing',
                'style-m_style-m_rbx-green_nougat_clothing',
                'style-m_style-m_roblox-shirt_nougat_clothing',
                'style-m_style-m_zipup-jacket_nougat_clothing'
            ]
        }
    },
    colorMapping: {
        v1: [
            'bl_color',
            'po_color',
            'bn_color',
            'do_color',
            'lo_color',
            'ng_color'
        ],
        v2: [
            'nougat_color',
            'pastelOrange_color',
            'lightOrange_color',
            'darkOrange_color'
        ]
    },
    events: {
        context: "avatarSignupScreen",
        avatarSelection: "avatarSelection",
        continue: "continue",
        signup: "signup",
        buttonClick: "buttonClick",
        modalAction: "modalAction",
        videoPreview: "videoPreview",
        dismiss: "dismiss"

    }
};

landingPageModule.constant("avatarLandingConstants", avatarLandingConstants);

export default avatarLandingConstants;

import landingPageModule from "../landingPageModule.js";
import { EnvironmentUrls, Resources, SignupFormValidatorGeneric } from "Roblox";

function rbxValidUsername(httpService) {
    "ngInject";

    return {
        require: 'ngModel',
        link: function (scope, ele, attrs, ctrl) {
            scope.signup.username = ele.val();
            scope.usernameValidationRequestNum = 0;

            scope.onChange = function () {
                var localIsEmpty = scope.signup.username === "" || angular.isUndefined(scope.signup.username);
                var localValidationMessage = "";
                var hasLocalValidationFailure = false;
                var requestNum = ++scope.usernameValidationRequestNum;

                if (requestNum === 1) {
                    if (scope.signupForm) {
                        if (scope.signupForm.signupUsername && scope.signup.username) {
                            scope.signupForm.signupUsername.$dirty = true;
                        }
                    } else if (scope.fbConnectForm) {
                        if (scope.fbConnectForm.username && scope.signup.username) {
                            scope.fbConnectForm.username.$dirty = true;
                        }
                    }
                }

                if (localIsEmpty) {
                    hasLocalValidationFailure = true;
                    localValidationMessage = Resources.AnimatedSignupFormValidator.usernameRequired;
                }
                else {
                    localValidationMessage = SignupFormValidatorGeneric.getInvalidUsernameMessage(scope.signup.username);
                    if (localValidationMessage !== "") {
                        hasLocalValidationFailure = true;
                    }
                }
                
                if (!scope.signup.birthdayDay || !scope.signup.birthdayMonth || !scope.signup.birthdayYear) {
                    hasLocalValidationFailure = true;
                    localValidationMessage = Resources.AnimatedSignupFormValidator.birthdayRequired;
                }

                ctrl.$setValidity("validusername", !hasLocalValidationFailure);
                if (hasLocalValidationFailure) {
                    ctrl.$validationMessage = localValidationMessage;
                }
                else {
                    ctrl.$validationMessage = "";

                    var urlConfig = {
                        url: EnvironmentUrls.authApi + "/v1/usernames/validate"
                    }
                    var params = {
                        username: scope.signup.username,
                        context: "Signup"
                    };

                    if (scope.signup.birthdayDay && scope.signup.birthdayMonth && scope.signup.birthdayYear) {
                        var birthday = new Date(Date.parse(scope.signup.birthdayMonth + " " + scope.signup.birthdayDay + ", " + scope.signup.birthdayYear));
                        if (angular.isDate(birthday)) {
                            params.birthday = birthday;
                        }
                    }

                    httpService.httpGet(urlConfig, params).then(function (result) {
                        if (requestNum == scope.usernameValidationRequestNum) {
                            var unique = true;
                            var valid = true;
                            var validationMessage = "";

                            if (result.code === 1) {
                                unique = false;
                                validationMessage = Resources.AnimatedSignupFormValidator.alreadyTaken;
                            }
                            else if (result.code === 2) {
                                valid = false;
                                validationMessage = Resources.AnimatedSignupFormValidator.cantBeUsed;
                            }
                            else if (result.code === 10) {
                                valid = false;
                                validationMessage = Resources.AnimatedSignupFormValidator.cantBeUsedPii;
                            }
                            else if (result.code === 12) {
                                valid = false;
                                validationMessage = Resources.AnimatedSignupFormValidator.usernameNotAvailable;
                            }

                            ctrl.$setValidity('unique', unique);
                            ctrl.$setValidity('moderated', valid);
                            if (ctrl.$invalid) {
                                if (validationMessage != "") {
                                    ctrl.$validationMessage = validationMessage;
                                }
                            } else {
                                ctrl.$validationMessage = "";
                            }
                        }
                    }, function (response) {
                        var errorCode = httpService.getPrimaryApiErrorCode(response);
                        if (errorCode === 2) {
                            ctrl.$setValidity('moderated', false);
                            ctrl.$validationMessage = Resources.AnimatedSignupFormValidator.birthdayRequired;
                        }
                    });
                }
            }
            //Validate on pageload
            scope.$evalAsync(function () {
                // Wait for angular to finish loading everything (like scope.signupForm.signupUsername) before checking inputs.
                scope.onChange();
            });
        }
    }
}

landingPageModule.directive("rbxValidUsername", rbxValidUsername);

export default rbxValidUsername;
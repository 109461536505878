import landingPageModule from "../landingPageModule"
import { Resources, SignupFormValidatorGeneric } from "Roblox";

function rbxValidPassword() {
    "ngInject";

    return {
        require: 'ngModel',
        link: function (scope, ele, attrs, ctrl) {
            scope.$watch(function () {
                return !angular.isUndefined(ctrl.$modelValue) && SignupFormValidatorGeneric.getInvalidPasswordMessage(ctrl.$modelValue, scope.signup.username);
            }, function (currentValue) {
                ctrl.$setValidity('password', angular.isString(ctrl.$modelValue) && currentValue === '');
                ctrl.$validationMessage = angular.isString(ctrl.$modelValue) ? currentValue : Resources.AnimatedSignupFormValidator.passwordRequired;
                scope.$unitTestValidationMessage = ctrl.$validationMessage; //For unit tests
            });
        }
    }
}
landingPageModule.directive("rbxValidPassword", rbxValidPassword);

export default rbxValidPassword;
import { Lang } from 'Roblox';
import angular from 'angular';

let landingPage = angular
    .module("landingPage", ["robloxApp", "modal", "captchaV2", "roblox.formEvents"])
    .config(['languageResourceProvider', function (languageResourceProvider) {
        languageResourceProvider.setLanguageKeysFromFile(Lang.LandingResources);
        languageResourceProvider.setLanguageKeysFromFile(Lang.MessagesResources);
        languageResourceProvider.setLanguageKeysFromFile(Lang.SignUpResources);
        languageResourceProvider.setLanguageKeysFromFile(Lang.CaptchaResources);
        languageResourceProvider.setLanguageKeysFromFile(Lang.ControlsResources);
        languageResourceProvider.setLanguageKeysFromFile(Lang["CommonUI.Features"]);
        languageResourceProvider.setLanguageKeysFromFile(Lang.LandingAvatarResources);
    }]);

export default landingPage;
import landingPageModule from '../landingPageModule';
import { EnvironmentUrls } from "Roblox";

const signupConstants = {
    urls: {
        signUpApi: "/v2/signup",
        metaData: "/landing/metadata",
        metadataV2: "/v2/metadata",
        weChatSignUp: "/v2/wechat/signup",
        termsOfUse: "/info/terms",
        privacy: "/info/privacy",
        gamesNewUser: "/games?nu=true",
        homePageNewUser: "/home?nu=true",
        homePage: "/home"
    },
    genderType: {
        unknown: 1,
        male: 2,
        female: 3
    },
    inputType: {
        password: "password",
        text: "text"
    },
    urlQueryNames: {
        locale: "locale"
    },
    referralQueryNames: {
        rbxSource: "rbx_source",
        rbxCampaign: "rbx_campaign",
        rbxMedium: "rbx_medium",
        reqId: "req_id",
        offerId: "offer_id"
    },
    maxSignUpAge: 100,
    context: "Multiverse",
    maxNumberOfDates: 31,
    anchorOpeningTag: '<a target="_blank" href="',
    anchorClosingTag: "</a>",
    localeParamName: "?locale=",
    nonLeapYear: "2014",
    newUserParam: "nu=true",
    apiUrls: {
        enrollAbtestingApi: {
            url: EnvironmentUrls.abtestingApiSite + "/v1/enrollments",
            retryable: false,
            withCredentials: true
        }
    },
    abtestingRequest: {
        subjectType: "User"

    },
    abtestingResponse: {
        status: {
            enrolled: "Enrolled"
        },
        landingTohomePageVariation: 2
    },
    counters: {
        prefix: "WebsiteSignUp_",
        firstAttempt: "FirstAttempt",
        attempt: "Attempt",
        success: "Success",
        captcha: "Captcha",
        tooManyAttempts: "TooManyAttempts",
        passwordInvalid: "PasswordInvalid",
        usernameInvalid: "UsernameInvalid",
        usernameTaken: "UsernameTaken",
        unknownError: "UnknownError"
    },
    signUpSubmitButtonName: "signupSubmit",
    weChatSignUpSubmitButtonName: "weChatSignupSubmit",
    emailAtSignupValue: {
        none: "NoEmail",
        optional: "EmailOptional",
        required: "Email"
    },
    birthdayPicker: {
        year: {
            id: "YearDropdown",
            class: "year",
            name: "birthdayYear",
            type: "year"
        },
        month: {
            id: "MonthDropdown",
            class: "month",
            name: "birthdayMonth",
            type: "month"
        },
        day: {
            id: "DayDropdown",
            class: "day",
            name: "birthdayDay",
            type: "day"
        }
    },
    defaultDateOrdering: {
        month: 0,
        day: 1,
        year: 2
    },
    defaultDateParts: {
        0: {
            options: [{
                label: "Month",
                value: null
            }],
            id: "MonthDropdown",
            class: "month",
            name: "birthdayMonth",
            type: "month"
        },
        1: {
            options: [{
                label: "Day",
                value: null
            }],
            id: "DayDropdown",
            class: "day",
            name: "birthdayDay",
            type: "day"
        },
        2: {
            options: [{
                label: "Year",
                value: null
            }],
            id: "YearDropdown",
            class: "year",
            name: "birthdayYear",
            type: "year"
        }
    }
};

landingPageModule.constant("signupConstants", signupConstants);

export default signupConstants;
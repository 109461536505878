import { EnvironmentUrls, UrlParser, FormEvents, Intl, Resources, Auth, SignupMeta } from 'Roblox';
import landingPageModule from '../landingPageModule';

function signupController(
  $injector,
  $scope,
  $log,
  $timeout,
  httpService,
  urlService,
  eventTrackerService,
  signupService,
  signupConstants,
  captchaV2Constants,
  languageResource,
  eventStreamService,
  avatarLandingConstants
) {
  'ngInject';

  $scope.signup = {};
  $scope.layout = {
    context: signupConstants.context,
    passwordInputType: signupConstants.inputType.password,
    isFirstSignUpSubmit: true,
    orderedBirthdayParts: {
      parts: signupConstants.defaultDateParts,
      typeOrder: signupConstants.defaultDateOrdering
    },
    isSubmitting: false
  };
  $scope.signUpParams = $scope.signUpParams || {};
  const intl = new Intl();

  $scope.genderType = signupConstants.genderType;
  $scope.signup.gender = $scope.genderType.unknown;
  $scope.birthdayToPrefill = $scope.$parent.birthdayToPrefill;

  $scope.captchaActivated = false;
  $scope.captchaActionTypes = captchaV2Constants.captchaActionTypes;

  function setMonthList() {
    $scope.layout.months = [
      { value: 'Jan', label: languageResource.get('Label.January') },
      { value: 'Feb', label: languageResource.get('Label.February') },
      { value: 'Mar', label: languageResource.get('Label.March') },
      { value: 'Apr', label: languageResource.get('Label.April') },
      { value: 'May', label: languageResource.get('Label.May') },
      { value: 'Jun', label: languageResource.get('Label.June') },
      { value: 'Jul', label: languageResource.get('Label.July') },
      { value: 'Aug', label: languageResource.get('Label.August') },
      { value: 'Sep', label: languageResource.get('Label.September') },
      { value: 'Oct', label: languageResource.get('Label.October') },
      { value: 'Nov', label: languageResource.get('Label.November') },
      { value: 'Dec', label: languageResource.get('Label.December') }
    ];
    const placeholder = languageResource.get('Label.Month');
    setOrderedBirthdayPart('month', $scope.layout.months, placeholder);
  }

  function setDateList() {
    const dates = [];
    const maximumDate = signupConstants.maxNumberOfDates;

    for (let i = 1; i <= maximumDate; i++) {
      // add leading zero (1 => 01 and 10 => 10)
      const day = `0${i}`.slice(-2);
      const i18nDay = {
        day,
        value: day,
        label: $scope.isAsianBirthdayUsed
          ? intl.getFormattedDateString(day, languageResource.get('Label.Day'))
          : day
      };
      dates.push(i18nDay);
    }

    $scope.layout.dates = dates;

    const placeholder = languageResource.get('Label.Day');
    setOrderedBirthdayPart('day', dates, placeholder);
  }

  function setYearList() {
    const years = [];
    const now = new Date();
    const currentYear = now.getFullYear();
    const minimumYear = currentYear - signupConstants.maxSignUpAge;

    for (let i = currentYear; i > minimumYear; i--) {
      const i18nYear = {
        year: i,
        value: i,
        label: $scope.isAsianBirthdayUsed
          ? intl.getFormattedDateString(i, languageResource.get('Label.Year'))
          : i
      };
      years.push(i18nYear);
    }

    $scope.layout.years = years;

    const placeholder = languageResource.get('Label.Year');
    setOrderedBirthdayPart('year', years, placeholder);
  }

  function setOrderedBirthdayPart(partName, value, placeholder) {
    const partIndex = $scope.layout.orderedBirthdayParts.typeOrder[partName];

    $scope.layout.orderedBirthdayParts.parts[partIndex] = {
      options: value,
      idName: signupConstants.birthdayPicker[partName].id,
      className: signupConstants.birthdayPicker[partName].class,
      birthdayName: signupConstants.birthdayPicker[partName].name,
      placeholder
    };
  }

  function buildLinkWithLocale(url, locale) {
    if (locale) {
      return url + signupConstants.localeParamName + locale;
    }

    return url;
  }

  function getLocale() {
    const locale = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.urlQueryNames.locale)
      : null;
    return locale ? encodeURIComponent(locale) : null;
  }

  function getReferralData() {
    let source = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.rbxSource)
      : null;
    let campaign = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.rbxCampaign)
      : null;
    let medium = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.rbxMedium)
      : null;
    let requestSessionId = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.reqId)
      : null;
    let offerId = UrlParser
      ? UrlParser.getParameterValueByName(signupConstants.referralQueryNames.offerId)
      : null;

    source = source ? encodeURIComponent(source) : null;
    campaign = campaign ? encodeURIComponent(campaign) : null;
    medium = medium ? encodeURIComponent(medium) : null;
    requestSessionId = requestSessionId ? encodeURIComponent(requestSessionId) : null;
    offerId = offerId ? encodeURIComponent(offerId) : null;

    if (!source && !campaign && !medium && !requestSessionId && !offerId) {
      return null;
    }

    return {
      source,
      campaign,
      medium,
      requestSessionId,
      offerId
    };
  }

  function setSignUpFormValidatorResources() {
    if (!Resources) {
      return;
    }

    // TODO: update this once AnimatedSignupFormValidator is moved to authsite
    Resources.AnimatedSignupFormValidator = {
      maxValid: languageResource.get('Response.TooManyAccountsWithSameEmailError'),
      invalidEmail: languageResource.get('Response.InvalidEmail'),
      invalidBirthday: languageResource.get('Response.InvalidBirthday'),
      loginFieldsRequired: languageResource.get('Response.UsernamePasswordRequired'),
      loginFieldsIncorrect: languageResource.get('Response.UsernameOrPasswordIncorrect'),
      doesntMatch: languageResource.get('Response.PasswordMismatch'),
      passwordIsUsername: languageResource.get('Response.PasswordContainsUsernameError'),
      requiredField: languageResource.get('Label.Required'),
      tooShort: languageResource.get('Response.PasswordWrongShort'),
      weakKey: languageResource.get('Response.PasswordComplexity'),
      invalidCharacters: languageResource.get('Response.SpaceOrSpecialCharaterError'),
      invalidName: languageResource.get('Response.UsernameAllowedCharactersError'),
      cantBeUsed: languageResource.get('Response.BadUsername'),
      cantBeUsedPii: languageResource.get('Response.UsernamePrivateInfo'),
      alreadyTaken: languageResource.get('Response.UsernameAlreadyInUse'),
      userNameInvalidLength: languageResource.get('Response.UsernameInvalidLength'),
      startsOrEndsWithUnderscore: languageResource.get('Response.UsernameInvalidUnderscore'),
      moreThanOneUnderscore: languageResource.get('Response.UsernameTooManyUnderscores'),
      birthdayRequired: languageResource.get('Response.BirthdayMustBeSetFirst'),
      passwordRequired: languageResource.get('Response.PleaseEnterPassword'),
      usernameRequired: languageResource.get('Response.PleaseEnterUsername'),
      passwordConfirmationRequired: languageResource.get('Response.PasswordConfirmation'),
      usernameNoRealNameUse: languageResource.get('Message.Username.NoRealNameUse'),
      passwordMinLength: languageResource.get('Message.Password.MinLength'),
      usernameNotAvailable: languageResource.get('Response.UsernameNotAvailable')
    };
  }

  function prefillBirthdayIfNecessary() {
    if ($scope.birthdayToPrefill) {
      const birthday = new Date($scope.birthdayToPrefill);
      const dateIndex = birthday.getDate() - 1;
      const dateToPrefill = $scope.layout.dates[dateIndex].value;
      $scope.signup.birthdayDay = dateToPrefill;
      const monthIndex = birthday.getMonth();
      const prefillMonth = $scope.layout.months[monthIndex].value;
      $scope.signup.birthdayMonth = prefillMonth;
      const prefillYearInt = birthday.getFullYear();
      const prefillYear = $scope.layout.years.find(({ year }) => year === prefillYearInt).value;
      $scope.signup.birthdayYear = prefillYear.toString();

      // lock birthday if user <18
      const now = new Date();
      const eighteenYearsAgo = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate());
      const ageDiff = now - birthday;
      const isUnder18 = ageDiff < eighteenYearsAgo.getMilliseconds();
      $scope.layout.shouldDisableBirthdaySelect = isUnder18;
    }
  }

  $scope.init = function () {
    $scope.isAsianBirthdayUsed = intl && intl.isAsianLanguage();
    $scope.layout.orderedBirthdayParts.typeOrder = new Intl()
      .getDateTimeFormatter()
      .getOrderedDateParts();
    setSignUpFormValidatorResources();
    setMonthList();
    setDateList();
    setYearList();
    prefillBirthdayIfNecessary();

    const locale = getLocale();
    const termsOfUseLink = EnvironmentUrls.websiteUrl + signupConstants.urls.termsOfUse;
    const privacyLink = EnvironmentUrls.websiteUrl + signupConstants.urls.privacy;
    const termsOfUseLocalizedLink = buildLinkWithLocale(termsOfUseLink, locale);
    const privacyLocalizedLink = buildLinkWithLocale(privacyLink, locale);
    $scope.layout.termsOfUseLinkElement = `${
      signupConstants.anchorOpeningTag + termsOfUseLocalizedLink
    }">${languageResource.get('Label.TermsOfUse')}${signupConstants.anchorClosingTag}`;
    $scope.layout.privacyLinkElement = `${
      signupConstants.anchorOpeningTag + privacyLocalizedLink
    }">${languageResource.get('Description.PrivacyPolicy')}${signupConstants.anchorClosingTag}`;

    // This call is used for experimentation / AB testing variants
    signupService.getMetadataV2().then(
      function (data) {
        $scope.emailAtSignupExperimentationValue = data?.EmailAtSignupExperimentationValue;
      },
      function (error) {
        $log.debug('getMetadataV2 failed: ', error);
      }
    );
  };

  $scope.handleCaptchaError = function (errorCode) {
    let errorText;

    switch (errorCode) {
      case captchaV2Constants.errorCodes.internal.failedToLoadProviderScript:
        errorText = languageResource.get('Response.CaptchaErrorFailedToLoad');
        break;
      default:
        errorText = languageResource.get('Response.CaptchaErrorFailedToVerify');
    }

    // Accepted way to prevent calling "$digest" again when digest is already in progress
    // If this callback fires as the result of an asynchronous request, we need to trigger
    // $digest manually, which can be accomplished with $timeout. We can't call $scope.$apply
    // because this will cause an error if it is already in progress.
    // https://davidburgos.blog/correctly-fix-angularjs-error-digest-already-in-progress/
    $timeout(function () {
      $scope.isSubmitting = false;
      $scope.signupForm.$generalError = true;
      $scope.signupForm.$generalErrorText = errorText;
    }, 0);
  };

  $scope.handleCaptchaDismiss = function () {
    $scope.layout.isSubmitting = false;
  };

  $scope.handleCaptchaSuccess = function (captchaData) {
    $scope.captchaActivated = false;
    $scope.submitSignup(false, captchaData);
  };

  $scope.setGender = function (e, genderType, lockField) {
    if (e) {
      e.preventDefault();
    }

    if ($scope.layout.isGenderDisabled) {
      return;
    }

    $scope.signup.gender = genderType;
    $scope.layout.isGenderDisabled = lockField;
  };

  $scope.isBirthdayFormDirty = function () {
    // Birthday Form can only be dirty when it exists
    if (
      $scope.signupForm.birthdayMonth &&
      $scope.signupForm.birthdayDay &&
      $scope.signupForm.birthdayYear
    ) {
      return (
        $scope.signupForm.birthdayMonth.$dirty &&
        $scope.signupForm.birthdayDay.$dirty &&
        $scope.signupForm.birthdayYear.$dirty &&
        $scope.signupForm.birthdayMonth.$modelValue !== null &&
        $scope.signupForm.birthdayDay.$modelValue !== null &&
        $scope.signupForm.birthdayYear.$modelValue !== null
      );
    }
    return false;
  };

  $scope.isBirthdayInvalid = function () {
    return (
      ($scope.badSubmit || $scope.isBirthdayFormDirty()) &&
      (!$scope.isValidBirthday($scope.signup.birthdayDay) ||
        $scope.signupForm.birthdayYear.$invalid)
    );
  };

  $scope.getBirthdayInvalidMessage = function () {
    return $scope.isBirthdayInvalid() ? languageResource.get('Response.InvalidBirthday') : '';
  };

  $scope.isValidBirthday = function (day, onlyValidateDay) {
    let year = $scope.signup.birthdayYear;

    if (!year && onlyValidateDay) {
      // set to non-leap year to be conservative when determining which days are available for the selected month
      year = signupConstants.nonLeapYear;
    }

    const month = $scope.signup.birthdayMonth;
    day = parseInt(day); // convert day to integer

    if (!year || !month || !day) {
      return false;
    }

    // Make sure we can create a valid date object
    const testDate = new Date(`${month} ${day} ${year}`);
    if (Object.prototype.toString.call(testDate) !== '[object Date]' || isNaN(testDate.getTime())) {
      return false;
    }

    // checks that it is actually a valid day in that month (like feb 31 doesn't exist but would generate a valid Date)
    if (testDate.getDate() !== day) {
      return false;
    }

    // age limits
    const today = new Date();
    const isBirthdayValid =
      testDate.getTime() < today.getTime() && testDate.getFullYear() > today.getFullYear() - 100;

    if (!isBirthdayValid) {
      return false;
    }

    return true;
  };

  $scope.shouldOfferEmailAtSignup = function () {
    return (
      $scope.emailAtSignupExperimentationValue &&
      $scope.emailAtSignupExperimentationValue !== signupConstants.emailAtSignupValue.none
    );
  };

  $scope.isEmailAtSignupOptional = function () {
    return $scope.emailAtSignupExperimentationValue === signupConstants.emailAtSignupValue.optional;
  };

  $scope.hasValidEmail = function () {
    if (!$scope.shouldOfferEmailAtSignup()) {
      return true;
    }

    if ($scope.isEmailAtSignupOptional() && !$scope.signup.email) {
      return true;
    }

    const re = /\S+@\S+\.\S+/;
    return re.test($scope.signup.email);
  };

  $scope.shouldShowEmailField = function () {
    return $scope.shouldOfferEmailAtSignup() && $scope.isValidBirthday($scope.signup.birthdayDay);
  };

  $scope.getEmailPlaceholderText = function () {
    return $scope.isUnder13()
      ? languageResource.get('Label.EmailRequirementsUnder13')
      : languageResource.get('Label.Email');
  };

  $scope.getEmailLabelText = function () {
    const isEmailOptional = $scope.isEmailAtSignupOptional();
    const isUnder13 = $scope.isUnder13();

    if (isEmailOptional) {
      return isUnder13
        ? languageResource.get('Label.EmailRequirementsUnder13Optional')
        : languageResource.get('Label.EmailOptional');
    }

    return isUnder13
      ? languageResource.get('Label.EmailRequirementsUnder13')
      : languageResource.get('Label.Email');
  };

  $scope.isUnder13 = function () {
    const year = $scope.signup.birthdayYear;
    const month = $scope.signup.birthdayMonth;
    const day = $scope.signup.birthdayDay;

    if (!year || !month || !day) {
      return false;
    }

    const testDate = new Date(`${month} ${day} ${year}`);
    const u13ThresholdDate = new Date();
    u13ThresholdDate.setFullYear(u13ThresholdDate.getFullYear() - 13);
    return testDate > u13ThresholdDate;
  };

  $scope.togglePasswordVisibility = function () {
    if ($scope.layout.showPassword) {
      $scope.layout.showPassword = false;
      $scope.layout.passwordInputType = signupConstants.inputType.password;
    } else {
      $scope.layout.showPassword = true;
      $scope.layout.passwordInputType = signupConstants.inputType.text;
    }
  };

  $scope.isFormValid = function () {
    return $scope.signupForm.$valid;
  };

  let wasPasswordBoxClicked = false;
  $scope.passwordBoxClicked = function () {
    wasPasswordBoxClicked = true;
  };

  $scope.getHintForUsername = function () {
    if (!$scope.signup.username && !$scope.isSignupFormDarkThemeEnabled) {
      return Resources.AnimatedSignupFormValidator.usernameNoRealNameUse;
    }
    return $scope.badSubmit || $scope.signupForm.signupUsername.$dirty
      ? $scope.signupForm.signupUsername.$validationMessage
      : '';
  };

  $scope.getUsernamePlaceholder = function () {
    if (!$scope.isSignupFormDarkThemeEnabled) {
      return languageResource.get('Label.Username');
    }
    return Resources.AnimatedSignupFormValidator.usernameNoRealNameUse;
  };

  $scope.getPasswrodPlaceholder = function () {
    if ($scope.isSignupFormDarkThemeEnabled) {
      return languageResource.get('Label.PasswordPlaceholder');
    }
    return languageResource.get('Label.Password');
  };

  $scope.getHintForPassword = function () {
    if (!$scope.signup.password && wasPasswordBoxClicked && !$scope.isSignupFormDarkThemeEnabled) {
      return Resources.AnimatedSignupFormValidator.passwordMinLength;
    }
    if (!$scope.signup.password && $scope.isSignupFormDarkThemeEnabled) {
      return '';
    }
    return $scope.badSubmit || $scope.signupForm.signupPassword.$dirty
      ? $scope.signupForm.signupPassword.$validationMessage
      : '';
  };

  $scope.getErrorMessageForEmail = function () {
    if (!$scope.hasValidEmail()) {
      return Resources.AnimatedSignupFormValidator.invalidEmail;
    }
    return '';
  };

  $scope.setGeneralError = function () {
    $scope.signupForm.$generalError = true;
    $scope.signupForm.$generalErrorText = 'Sorry, an error occurred.';
  };

  $scope.handleSignupErrors = function (response) {
    let numberOfReasonsHandled = 0;

    if (response.reasons.indexOf('Captcha') !== -1) {
      let dxBlob = '';
      if (response.errors && response.errors.length > 0 && response.errors[0].fieldData) {
        dxBlob = response.errors[0].fieldData;
      }
      handleCaptcha(dxBlob);
      numberOfReasonsHandled += 1;
    }
    if (response.reasons.indexOf('PasswordInvalid') !== -1) {
      $scope.signupForm.signupPassword.$setValidity('password', false);
      $scope.signupForm.signupPassword.$passwordMessage = 'Password is invalid';
      $scope.incrementEphemeralCounter(signupConstants.counters.passwordInvalid);
      numberOfReasonsHandled += 1;
    }
    if (response.reasons.indexOf('UsernameInvalid') !== -1) {
      $scope.signupForm.signupUsername.$setValidity('validusername', false);
      $scope.signupForm.signupUsername.$usernameMessage = 'Username is invalid';
      $scope.incrementEphemeralCounter(signupConstants.counters.usernameInvalid);
      numberOfReasonsHandled += 1;
    }
    if (response.reasons.indexOf('UsernameTaken') !== -1) {
      $scope.signupForm.signupUsername.$setValidity('unique', false);
      $scope.signupForm.signupUsername.$usernameMessage = 'Username is taken';
      $scope.incrementEphemeralCounter(signupConstants.counters.usernameTaken);
      numberOfReasonsHandled += 1;
    }

    if (numberOfReasonsHandled < response.reasons.length) {
      $scope.incrementEphemeralCounter(signupConstants.counters.unknownError);
      $scope.setGeneralError();
    }
  };

  $scope.incrementSignUpSubmitCounters = function () {
    $scope.incrementEphemeralCounter(signupConstants.counters.attempt);

    if ($scope.layout.isFirstSignUpSubmit) {
      $scope.layout.isFirstSignUpSubmit = false;
      $scope.incrementEphemeralCounter(signupConstants.counters.firstAttempt);
    }
  };

  $scope.badSubmit = false;

  $scope.sendConversionEvent = function (callback) {
    if (typeof gtag === 'undefined' || !gtag || !gtag.conversionEvents) return callback();
    // In case gtag fails
    const id = setTimeout(callback, 2000);
    gtag('event', 'conversion', {
      send_to: gtag.signupConversionEvent,
      event_callback() {
        clearTimeout(id);
        callback();
      },
      event_timeout: 2000
    });
  };

  $scope.submitSignup = function (isUserTriggered, captchaData) {
    if (isUserTriggered) {
      $scope.sendInteractionClickEvent(signupConstants.signUpSubmitButtonName);
    }

    if (
      !$scope.isFormValid() ||
      !$scope.isValidBirthday($scope.signup.birthdayDay) ||
      !$scope.hasValidEmail()
    ) {
      $scope.badSubmit = true;
      return;
    }
    $scope.badSubmit = false;

    $scope.layout.isSubmitting = true;

    if (isUserTriggered) {
      $scope.incrementSignUpSubmitCounters();
    }

    const birthday = `${$scope.signup.birthdayDay} ${$scope.signup.birthdayMonth} ${$scope.signup.birthdayYear}`;

    const referralData = getReferralData();

    const signUpParams = {
      username: $scope.signup.username,
      password: $scope.signup.password,
      birthday,
      gender: $scope.signup.gender,
      isTosAgreementBoxChecked: true,
      context: $scope.signupForm.context,
      referralData
    };

    Object.assign(signUpParams, $scope.$parent.avatarSignupMetadata);

    const locale = getLocale();

    if (locale) {
      signUpParams.locale = locale;
    }

    if ($scope.signup.email) {
      signUpParams.email = $scope.signup.email;
    }

    if (captchaData != null) {
      signUpParams.captchaToken = captchaData.captchaToken;
      signUpParams.captchaProvider = captchaData.captchaProvider;
    }
    signupService.signup(signUpParams).then(
      function (result) {
        $scope.incrementEphemeralCounter(signupConstants.counters.success);
        const params = urlService.getJsonFromQueryString();
        const parsedReturnUrl = urlService.getAbsoluteUrl(
          params.returnurl ? params.returnurl : signupConstants.urls.homePage
        );
        // deprioritize Auth.returnUrl since it is set only in loginService
        let returnUrl = parsedReturnUrl || (Auth && Auth.returnUrl);
        if (typeof returnUrl === 'string' && returnUrl.length > 0) {
          if (returnUrl.indexOf('?') === -1) {
            returnUrl += '?';
          } else {
            returnUrl += '&';
          }
          returnUrl += signupConstants.newUserParam;
          $scope.sendConversionEvent(() => (window.location.href = returnUrl));
        } else {
          const landingUrl = signupConstants.urls.homePageNewUser;
          if (SignupMeta && SignupMeta.isNewUserLandingAbTestingEnabled && result && result.data) {
            const { userId } = result.data;
            const data = [
              {
                SubjectType: signupConstants.abtestingRequest.subjectType,
                SubjectTargetId: userId,
                ExperimentName: SignupMeta.newUserLandingPageAbTestName
              }
            ];
            signupConstants.apiUrls.enrollAbtestingApi.timeout =
              SignupMeta.timeoutOnAbtestingEnrollEndpoint;
            httpService.httpPost(signupConstants.apiUrls.enrollAbtestingApi, data).then(
              function success(response) {
                if (response.data && response.data.length > 0) {
                  if (signUpParams.assetIds && signUpParams.assetIds.length > 1) {
                    // if there are assets in signup params, the ftux experiment is on
                    eventStreamService.sendEventWithTarget(
                      avatarLandingConstants.events.buttonClick,
                      avatarLandingConstants.events.context,
                      {
                        btn: avatarLandingConstants.events.signup,
                        body: $scope.$parent.avatarSignupMetadata.bodyId,
                        head: $scope.$parent.avatarSignupMetadata.headId,
                        clothing: $scope.$parent.avatarSignupMetadata.clothingId,
                        color: $scope.$parent.avatarSignupMetadata.colorId
                      }
                    );
                  }

                  const result = response.data[0]; // already know only one AB testing we are testing, no need to interated since page will be redirected anyway
                  const { abtestingResponse } = signupConstants;
                  if (result.Status === abtestingResponse.status.enrolled) {
                    $scope.sendConversionEvent(() => {
                      window.location.href =
                        result.Variation === abtestingResponse.landingTohomePageVariation
                          ? landingUrl
                          : signupConstants.urls.gamesNewUser;
                    });
                    return false;
                  }
                }

                $scope.sendConversionEvent(() => (window.location.href = landingUrl));
              },
              function error() {
                $scope.sendConversionEvent(() => (window.location.href = landingUrl));
              }
            );
          } else {
            $scope.sendConversionEvent(() => (window.location.href = landingUrl));
          }
        }
      },
      function (result) {
        $scope.handleSignupError(result.data, result.status);
      }
    );
  };

  $scope.handleSignupError = function (data, status) {
    $scope.badSubmit = true;
    $scope.layout.isSubmitting = false;
    if (status === 403) {
      $scope.handleSignupErrors(data);
    } else if (status === 429) {
      $scope.setGeneralError();
      $scope.incrementEphemeralCounter(signupConstants.counters.tooManyAttempts);
    } else {
      $scope.setGeneralError();
      $scope.incrementEphemeralCounter(signupConstants.counters.unknownError);
    }
  };

  $scope.incrementEphemeralCounter = function (name) {
    logEvent(signupConstants.counters.prefix + name);
  };

  $scope.sendInteractionClickEvent = function (fieldName) {
    if (FormEvents) {
      FormEvents.SendInteractionClick($scope.signupForm.context, fieldName);
    }
  };

  function handleCaptcha(dxBlob) {
    $scope.captchaReturnTokenInSuccessCb = true;
    $scope.captchaInputParams = { dataExchange: dxBlob };
    $scope.captchaActivated = true;
    $scope.layout.isSubmitting = true;
    $scope.incrementEphemeralCounter(signupConstants.counters.captcha);
  }

  function logEvent(event) {
    eventTrackerService.fireEvent(event);
  }

  $scope.init();
}

landingPageModule.controller('signupController', signupController);

export default signupController;

import landingPageModule from "../landingPageModule.js";

function avatarLanding(avatarLandingConstants) {
    "ngInject";

    return {
        restrict: "A",
        replace: false,
        templateUrl: avatarLandingConstants.templates.avatarLandingPage,
        controller: "avatarLandingPageController"
    }
}

landingPageModule.directive("avatarLanding", avatarLanding);

export default avatarLanding;
import landingPageModule from '../landingPageModule';

const landingPageConstants = {
    templates: {
        landingPage: "landing"
    },
    urls: {
        login: "/login",
        metadata: "/v1/metadata",
        // Note that metatdata api needs to be updated for v2.
        privacy: "/info/privacy"
    },
    urlQueryNames: {
        locale: "locale"
    },
    context: "Multiverse",
    mainShowSignupButtonEvent: "mainShowSignup",
    topShowSignupButtonEvent: "topShowSignup",
    hideSignupButtonEvent: "hideSignup",
    appButtonClickEvent: "AppLink",
    resolutionEvent: "resolutionMetadata",
    ftuxAvatarTestAvatarV1ContextV1Variation: 2,
    ftuxAvatarTestAvatarV2ContextV1Variation: 3,
    ftuxAvatarTestAvatarV1ContextV2Variation: 4,
    ftuxAvatarTestAvatarV2ContextV2Variation: 5
};

landingPageModule.constant("landingPageConstants", landingPageConstants);

export default landingPageConstants;

import landingPageModule from '../landingPageModule';
import { EnvironmentUrls } from "Roblox";

function landingPageService(httpService, landingPageConstants) {
    "ngInject";

    function getMetadata() {
        var urlConfig = {
            url: EnvironmentUrls.authApi + landingPageConstants.urls.metadata
        };
        return httpService.httpGet(urlConfig);
    }

    var landingPageService = {
        getMetadata: getMetadata
    };

    return landingPageService;
}

landingPageModule.factory("landingPageService", landingPageService);

export default landingPageService;
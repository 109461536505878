import landingPageModule from "../landingPageModule"
import { EnvironmentUrls, FormEvents, EventStream } from "Roblox";

function landingPageController($scope, $window, landingPageConstants) {
    "ngInject";
    
    $scope.landingLayout = {
        googlePlayStoreLink: EnvironmentUrls.googlePlayStoreLink,
        amazonStoreLink: EnvironmentUrls.amazonStoreLink,
        appStoreLink: EnvironmentUrls.appStoreLink,
        windowsStoreLink: EnvironmentUrls.windowsStoreLink,
        xboxStoreLink: EnvironmentUrls.xboxStoreLink,
        loginLink: EnvironmentUrls.websiteUrl + landingPageConstants.urls.login
    };

    $scope.appClick = function (name) {
        sendInteractionClickEvent(name + landingPageConstants.appButtonClickEvent);
    }

    $scope.avatarSignupMetadata = {};

    $scope.displayAvatarSignup = false;


    function sendInteractionClickEvent(fieldName) {
        if (FormEvents) {
            FormEvents.SendInteractionClick(landingPageConstants.context, fieldName);
        }
    }

    function sendResolutionEvent() {
        if (EventStream && EventStream.SendEvent) {
            var properties = {
                screenResolution: $window.screen.width * $window.devicePixelRatio + "x" + $window.screen.height * $window.devicePixelRatio,
                actualScreenSize: $window.screen.width + "x" + $window.screen.height,
                zoomRatio: $window.devicePixelRatio,
                windowSize: $window.innerWidth + "x" + $window.innerHeight
            };
            EventStream.SendEvent(landingPageConstants.resolutionEvent, landingPageConstants.context, properties);
        }
    }

    function init() {
        // this is the toggle to enbale exp system
        if ($scope.useExperimentationPlatformForFtux) {
            $scope.displayAvatarSignup = $scope.isFtuxAvatarEnabled;
            $scope.avatarSignupMetadata.displayAvatarV2 = $scope.ftuxAvatarVersion === 2;
            $scope.avatarSignupMetadata.displayContextV2 = $scope.ftuxContextVersion === 2;
        } else {
            $scope.displayAvatarSignup = $scope.abTestVariation >=2; //$window.location.search.includes('displayAvatar');
            $scope.avatarSignupMetadata.abTestVariation = $scope.abTestVariation;
            $scope.avatarSignupMetadata.displayAvatarV2 = $scope.abTestVariation === 4 || $scope.abTestVariation === 5; //$window.location.search.includes('avatarv2');
            $scope.avatarSignupMetadata.displayContextV2 = $scope.abTestVariation === 3 || $scope.abTestVariation === 5; //$window.location.search.includes('contextv2');
        }
        sendResolutionEvent();
    };

    init();
};

landingPageModule.controller("landingPageController", landingPageController);

export default landingPageController;

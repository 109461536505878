import landingPageModule from '../landingPageModule';

function rbxValidEmail() {
  'ngInject';

  return {
    require: 'ngModel',
    link(scope, ele, attrs, ctrl) {
      scope.signup.email = ele.val();
      scope.onEmailUpdated = function () {
        if (scope.signup.email) {
          ctrl.$setValidity('validEmail', scope.hasValidEmail());
        } else {
          ctrl.$setValidity('validEmail', scope.isEmailAtSignupOptional ? null : false);
        }
      };
    }
  };
}

landingPageModule.directive('rbxValidEmail', rbxValidEmail);

export default rbxValidEmail;
